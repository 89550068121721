:root {
  font-size: 90%;
  font-family: "Nunito Sans", sans-serif !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  /* margin: auto 15px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  font-family: "Nunito Sans", sans-serif !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}



/* ScrollBar Global Pages*/

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar {
  background: lightgray;
}

::-webkit-scrollbar-thumb {
  background: #343946;
}



/* Modal Styles */

.modal-first-backdrop {
  z-index: 400 !important;
}

.modal-first-dialog {
  z-index: 405 !important;
}


.modal-second-backdrop {
  z-index: 500 !important;
}

.modal-second-dialog {
  z-index: 501 !important;
}


.modal-loading-square {
  width: 10vw !important;
  min-width: 10vw !important;
  min-width: 10vw !important;
}

.modal-70 {
  width: 80vw;
  min-width: 80vw;
  min-width: 80vw;
}

.modal-85 {
  width: 85vw;
  min-width: 85vw;
  min-width: 85vw;
}


.modal-95 {
  width: 95vw;
  min-width: 95vw;
  min-width: 95vw;
}



/* Table Styles */

.text-wrap-normal {
  word-wrap: normal !important;
}